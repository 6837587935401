import axios from 'axios';
import { FileChecksum } from "@rails/activestorage/src/file_checksum";
import { BlobRecord } from "@rails/activestorage/src/blob_record";

class BlobUpload {
  constructor(blob) {
    this.blob = blob;
    this.file = blob.file;

    const { url } = blob.directUploadData;

    this.url = url;
  }

  create(callback) {
    this.callback = callback;

    var formData = new FormData();
    formData.append('file', this.file);

    axios.post(this.url, formData).then(response => {
      this.requestDidLoad(response);
    }).catch(error => {
      this.requestDidError(error);
    });
  }

  requestDidLoad(response) {
    const { status, data } = response;
    if (status >= 200 && status < 300) {
      this.callback(null, data);
    } else {
      this.requestDidError(response);
    }
  }

  requestDidError(data) {
    const {response} = data;
    console.log(response);
    this.callback(`Error storing "${this.file.name}". Status: ${response.status}. ${this.formatErrorMessage(response.data)}`);
  }

  formatErrorMessage(data) {
    if (data.errors && Array.isArray(data.errors)) {
      return data.errors.map((error) => error.message).join(",");
    } else {
      return data.errors;
    }
  }
}

export class DocstoreDirectUpload {
  constructor(file, url, customHeaders = {}) {
    this.file = file;
    this.url = url;
    this.customHeaders = customHeaders;
  }

  create(callback) {
    FileChecksum.create(this.file, (error, checksum) => {
      if (error) {
        callback(error);
        return;
      }

      const blob = new BlobRecord(this.file, checksum, this.url, this.customHeaders);
      blob.create((record_error) => {
        if (record_error) {
          callback(record_error);
        } else {
          const upload = new BlobUpload(blob);
          upload.create((upload_error, response) => {
            if (upload_error) {
              callback(upload_error);
            } else {
              response = {...blob.toJSON(), ...response, ...{urlPrefix: 'docstore://'}};
              callback(null, response);
            }
          });
        }
      });
    });
  }
}
